a {
  text-decoration: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}
.react-tel-input .country-list {
  width: 250px !important;
}
.store-modal {
  padding: 20px;
}

.css-13cymwt-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem; */
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-13cymwt-control {
  min-height: 42px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1xc3v61-indicatorContainer {
  display: none !important;
}
.css-t3ipsp-control {
  border-color: transparent !important;
  color: #495057;
  border-radius: 0.7rem !important;
  padding: 8px;
  box-shadow: 0 0 1px black !important;
}
.password-input {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #555; /* Adjust the color as needed */
  cursor: pointer;
}
.forget-password-text {
  margin: 0;
}
.forget-password-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.table tr:hover {
  background-color: #eee4e4 !important;
  cursor: pointer;
}
.list-btn {
  border: none;
  transition: calc(0.3s);
}
.list-btn:hover {
  border: none;
  transform: scale(1.5);
}
.loyalty-program {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
  background: var(
    --new-linear,
    linear-gradient(180deg, #042e77 -16.87%, #042e77 118.72%)
  );
  color: #ffff;
  width: 50% !important ;
  min-height: 190px !important;
  transition: calc(0.4s);
}

.loyalty-program:hover {
  transform: scale(1.04);
}
.complaint-image {
  justify-content: space-between;
  padding: 15px;
}
.complaint-image img {
  border-radius: 10px;
}
.add-program {
  display: flex;
  justify-content: end;
  padding: 10px;
}

.loyalty-program-card {
  padding: 12px;
}
.loyalty-program-card p {
  color: #ffffffca;
  margin: 0;
}
.program-card {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid gray;
}
.program-card p {
  margin: 5px;
}
.program-card h4 {
  margin: 0;
  color: #ffff;
}
.card-img {
  height: 50px;
  width: 73px;
}
.card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.page-title {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgb(137, 137, 137);
  padding: 10px;
}
.back-btn {
  background-color: #d8e6ff;
  color: #000;
  transition: calc(0.3s);
}
.back-btn:hover {
  background-color: rgb(211, 211, 211);
  color: #000;
  transform: scale(1.1);
}

.loyaltyItem {
  background-color: #e6eeff;
  border-radius: 10px;
  padding: 14px;
  cursor: pointer;
}

.headerText {
  font-size: 16px;
  color: #000;
  margin: 0;
  padding-top: 5px;
}

.subheaderText {
  color: darkWhite;
  margin: 0;
  font-size: 12px;
  margin-bottom: 14px;
  padding-top: 8px;

  padding-bottom: 12px;
}

.darkView {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  background-color: #042e77;
  margin: -14px -14px -14px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tillText {
  margin: 0px;
  font-size: 15px;
  color: white;
}

.validText {
  font-size: 15px;
  color: white;
}
.cutleryImage {
  width: 28px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radioButtons {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.radioButtons label {
  display: flex;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem !important;
  align-items: center;
  gap: 10px;
}

.inputBox {
  width: 80%;
  max-width: 300px;
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
}
.offer-action-btn {
  border-radius: 50%;
  height: 33px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #042e77;
  transition: calc(0.3s);
  padding: 0 !important;
}
.offer-action-btn:hover {
  transform: scale(1.2);
}
.offer-acton-options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.offer-action-btn:after {
  display: none;
}

.light.btn-info {
  background-color: #eee4e4 !important;
  color: #ffff !important;
  border-color: #9b4444 !important;
}
.light.btn-info g [fill] {
  fill: #9b4444 !important;
}
.light.btn-info:hover g [fill] {
  background-color: #9b4444;
}
.switch-disable {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendor-winery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.hover-table {
  transition: all 0.5s;
  color: #9b4444;
}
.hover-table:hover {
  transform: scale(1.5);
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}
@media (max-width: 600px) {
  .inputBox {
    width: 90%;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    /* max-width: 601px; */
  }
}

@media screen and (max-width: 780px) {
  .store-cards {
    flex-wrap: wrap;
  }
  .loyalty-program {
    width: 100% !important;
  }
}
@media screen and (max-width: 400px) {
  .complaint-image img {
    width: 90px !important;
    height: 90px !important;
  }
  .add-program {
    justify-content: center;
    width: 100% !important;
  }
  .add-program button {
    width: 100% !important;
  }
}
.disabled-row {
  background-color: #e9ecef !important;
  color: #7d6f6c !important;
  pointer-events: none !important;
  opacity: 0.65 !important;
}

/* //================================================================? */
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #f5f5f5;
}

h3 {
  margin-bottom: 20px;
  color: #333;
}

/* OTP Inputs */
.otp-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.otp-inputs input {
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.otp-inputs input:focus {
  border-color: #80221d;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

/* Error message */
.error {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}
.image-view-modal div {
  height: 700px;
  width: 80vw;
}
.image-view-modal img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  max-width: none;
}
.cross {
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background-color: #ecf0f3 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}
.cross:hover {
  background-color: #fff !important;
}
