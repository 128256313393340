.item-details h3 {
  font-size: 26px;
  font-weight: 700;
  color: #9b4444;
}

.item-details p:nth-of-type(1) {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #22222299;
}

.item-details p:nth-of-type(2) {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  display: flex;
  align-items: center;
  gap: 5px;
}

.item2-details {
  text-align: end;
}

.item2-details p {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  color: #222222;
}

.item2-details .small-text {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
}

.item2-details button {
  padding: 12px;
}

.image-section {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.image-section img {
  width: 468px;
  height: 550px;

  border-radius: 12px 0px 0px 12px;
  object-fit: cover;
  background-color: #cbe0e2;
}

.image-section .image-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-section .image-box-internal {
  display: flex;
  gap: 10px;
}

.image-section .image-box-internal img:nth-of-type(1) {
  width: 304px;
  height: 271px;
  border-radius: 0;
}

.image-section .image-box-internal img:nth-of-type(2) {
  width: 304px;
  height: 271px;
  border-radius: 0px 12px 0px 0px;
}

.image-section .image-box-internal-2 {
  display: flex;
  gap: 10px;
}

.image-section .image-box-internal-2 img:nth-of-type(1) {
  width: 304px;
  height: 271px;
  border-radius: 0;
}

.image-section .image-box-internal-2 img:nth-of-type(2) {
  width: 304px;
  height: 271px;
  border-radius: 0px 0px 12px 0px;
}

.item-details-section {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #c68484;
  gap: 20px;
}

.item-details-section h1 {
  font-size: 24px;
  font-weight: 700;
  color: #9b4444;
}

.item-details-section p {
  font-size: 16px;
  font-weight: 400;
  color: #22222299;
}

.slot-card {
  padding: 30px;
  border: none;
  height: 500px;
  min-height: 300px;
  border-radius: 16px;
}

.slot-card h3 {
  font-size: 20px;
  font-weight: 700;
  color: #9b4444;
}
.slot-card .card-header {
  padding: 0;
  padding-bottom: 10px;
}

.slot-card span {
  font-size: 18px;
  font-weight: 600;
  color: #9b4444;
}

.theme-outline-btn {
  border: 1px solid #9b4444;
  border-radius: 8px;
  padding: 12px;
  color: #9b4444;
}

.review-section h3 {
  font-size: 24px;
  font-weight: 700;
  color: #9b4444;
}

.review-card {
  padding: 20px 0px;
  border-bottom: 1px solid #22222247;
}

.review-card h1 {
  font-size: 20px;
  font-weight: 700;
  color: #222222;
}

.review-card p {
  font-size: 14px;
  font-weight: 400;
  color: #22222299;
}

.review-card .review-text {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
}
.star-container {
  display: inline-flex;
}

.full-star,
.half-star,
.empty-star {
  width: 20px;
  height: 20px;
  background-size: cover;
}

.full-star {
  background-image: url("../../icons/star.png");
}

.empty-star {
  background-image: url("../../icons/star\ \(1\).png");
}

.half-star {
  position: relative;
  background-image: url("../../icons/star.png");
  background-size: cover;
  overflow: hidden;
}

.half-star::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #eeeeee; /* Adjust according to your background color */
}
.circle {
  object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
